import React, { useState } from 'react'
import AuthService from '../../../../services/auth'
import cutileiApi from '../../../../services/cutileiApi'
import AlertDialog from '../../../AlertDialog'
import NumberInput from '../../../Inputs/NumberInput'
import MoneyInput from '../../../Inputs/MoneyInput'
import Select from '../../../Inputs/Select'
import tipValidator from '../../../../validators/tipValidator'
import { ReactComponent as ButtonLoading } from '../../../../icons/loading2.svg'
import { Formik } from 'formik'
import * as FA from 'react-icons/fa'

import {
  Container,
  FormField,
  Row,
  Input,
  InfoText,
  MoneyTextMask,
  Button
} from './styles'

function BillTip ({
  bill,
  data: tip,
  professionals,
  enableEditting = true,
  onEdit: handleEdit,
  onDelete: handleDelete
}) {
  const [editting, setEditting] = useState (false)
  const [showAlertDialog, setShowAlertDialog] = useState (false)
  const [errorMessage, setErrorMessage] = useState (null)
  const [deleting, setDeleting] = useState (false)
  const token = AuthService.getToken ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  const deleteTip = async () => {
    setDeleting (true)
    try {
      await cutileiApi.delete (`/tips/${tip.id}`, requestConfig)
      setDeleting (false)
      handleDelete (tip)
    } catch (error) {
      setDeleting (false)
      console.log (error)
    }
  }

  const toggleEditting = () => setEditting (!editting)
  const toggleAlertDialog = () => setShowAlertDialog (!showAlertDialog)

  return editting ? (
    <Formik
      validationSchema={tipValidator}
      initialValues={{
        'professional': {
          label: tip.professional.nickname || tip.professional.name.split (' ')[0],
          value: tip.professional.id
        },
        'description': tip.description,
        'value': tip.value,
        'comission_percentage': tip.comission_percentage * 100
      }}
      onSubmit={async (values, { setSubmitting }) => {
        const tipData = {
          billId: bill.id,
          professionalId: values.professional.value,
          description: values.description,
          value: values.value,
          comissionPercentage: values.comission_percentage / 100
        }

        try {
          const { data: editedTip } = await cutileiApi.put (
            `/tips/${tip.id}`, tipData, requestConfig
          )

          setSubmitting (false)
          setEditting (false)
          handleEdit (editedTip)
        } catch (error) {
          if (error.response.data) setErrorMessage (error.response.data.message)
          setSubmitting (false)
          console.log (error)
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        handleChange,
        handleBlur, 
        handleSubmit
      }) => (
        <Container editting={editting} enableEditting={enableEditting}>
          <FormField>
            <Select
              name='professional'
              placeholder='Profissional...'
              value={values.professional}
              options={professionals.map (professional => ({
                label: professional.nickname || professional.name.split (' ')[0],
                value: professional.id
              }))}
              onChange={setFieldValue}
              onBlur={setFieldTouched}
              error={errors.professional}
              touched={touched.professional}
              containerStyles={{width: '100%'}}
            />
          </FormField>
          <FormField>
            <Input
              type='text'
              name='description'
              placeholder='Descrição'
              value={values.description}
              onChange={handleChange ('description')}
              onBlur={handleBlur ('description')}
            />
          </FormField>
          <FormField>
            <MoneyInput
              name='value'
              placeholder='Valor'
              value={values.value}
              onValueChange={setFieldValue}
              onBlur={handleBlur ('value')}
              style={{width: '100%'}}
            />
          </FormField>
          <FormField>
            <NumberInput
              id='comission_percentage'
              name='comission_percentage'
              value={values.comission_percentage}
              minValue={0}
              maxValue={100}
              onChange={setFieldValue}
              style={{width: '100%', marginLeft: -8}}
            />
          </FormField>
          <Row>
            <Button type='button' onClick={handleSubmit} disabled={isSubmitting}>
              {isSubmitting ? (
                <ButtonLoading style={{margin: 0}}/>
              ) : (
                <FA.FaSave color='#35A7FF' size={16}/>
              )}
            </Button>
            <Button type='button' onClick={toggleEditting}>
              <FA.FaTimes color='#FF3939' size={18}/>
            </Button>
          </Row>
          {/* {errorMessage && (
            <ErrorContainer>
              <InfoText>{errorMessage}</InfoText>
            </ErrorContainer>
          )} */}
        </Container>
      )}
    </Formik>
  ) : (
    <Container editting={editting} enableEditting={enableEditting}>
      <InfoText>
        {tip.professional.nickname || tip.professional.name.split (' ')[0]}
      </InfoText>
      <InfoText>
        {tip.description}
      </InfoText>
      <MoneyTextMask value={tip.value} style={{justifySelf: 'center'}}/>
      <InfoText>
        {tip.comission_percentage * 100}%
      </InfoText>
      {enableEditting && (
        <Row>
          <Button onClick={toggleEditting}>
            <FA.FaEdit color='#35A7FF' size={18}/>
          </Button>
          <Button onClick={toggleAlertDialog}>
            {deleting ? (
              <ButtonLoading style={{margin: 0}}/>
            ) : (
              <FA.FaTrash color='#FF3939' size={16}/>
            )}
          </Button>
        </Row>
      )}
      <AlertDialog
        visible={showAlertDialog}
        title='Atenção!'
        message='Deseja realmente remover esta caixinha da comanda?'
        confirmText='Sim'
        cancelText='Não'
        onConfirm={deleteTip}
        onClose={toggleAlertDialog}
        dangerous
      />
    </Container>
  )
}

export default BillTip
