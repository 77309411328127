import React from 'react'
import { withRouter } from 'react-router-dom'
import Comission from '../../../../../services/Comission'
import General from '../../../../../services/General'
import AdminNavbar from '../../../../../components/AdminNavbar'

import { 
  Container,
  InfoText,
  InfoContainer,
  Row,
  Info,
  SubTitle,
  MoneyTextMask
} from '../../styles'

function AdminMonthlyExpenses ({ history }) {
  const {
    monthExpenses,
    businessExpenses,
    professionalExpenses
  } = history.location.state

  const schedulesByMonth = history.location.state.schedulesByMonth.map (schedule => {
    return { ...schedule, business_id: schedule.business.id }
  })

  const schedulesByBusiness = General.groupBy (schedulesByMonth, 'business_id')

  return (
    <Container>
      <AdminNavbar/>
      <InfoContainer style={{height: 125, alignItems: 'center'}}>
        <SubTitle>Total do mês</SubTitle>
        <Info>
          <Row>
            <SubTitle>Despesa total:</SubTitle>
            <MoneyTextMask value={monthExpenses}/>
          </Row>
          <Row>
            <InfoText>Comissão dos salões:</InfoText>
            <MoneyTextMask value={businessExpenses}/>
          </Row>
          <Row>
            <InfoText>Comissão dos profissionais:</InfoText>
            <MoneyTextMask value={professionalExpenses}/>
          </Row>
        </Info>
      </InfoContainer>
      {schedulesByBusiness.map ((schedules, index) => {
        const { business} = schedules[0]
        const totalExpenses = Comission.getTotalEarnings (schedules, {value: 'cutilei'}, null)
        const businessExpenses = Comission.getBusinessEarnings (schedules, {value: 'cutilei'}, null)
        const professionalExpenses = Comission.getProfessionalEarnings (schedules, {value: 'cutilei'}, null)

        return (
          <InfoContainer key={index}>
            <Row style={{justifyContent: 'center'}}>
              <SubTitle>
                {business.name}:
              </SubTitle>
              <MoneyTextMask value={totalExpenses}/>
            </Row>
            <Row>
              <Row>
                <InfoText>Comissão do salão:</InfoText>
                <MoneyTextMask value={businessExpenses}/>
              </Row>
              <Row>
                <InfoText>Comissão dos profissionais:</InfoText>
                <MoneyTextMask value={professionalExpenses}/>
              </Row>
            </Row>
          </InfoContainer>
        )
      })}
    </Container>
  )
}

export default withRouter (AdminMonthlyExpenses)
