import React from 'react'
import { withRouter } from 'react-router-dom'
import AdminFinancial from '../../../../../services/AdminFinancial'
import Comission from '../../../../../services/Comission'
import AdminNavbar from '../../../../../components/AdminNavbar'
import Bill from '../../../../../components/Bill'

import { 
  Container, 
  InfoText, 
  InfoContainer,
  Row,
  Info,
  SubTitle,
  MoneyTextMask
} from '../../styles'

function Daily ({ history }) {
  const { day, dayBills, daySchedules } = history.location.state
  const dailyTotalEarnings = AdminFinancial.getAdminEarnings (dayBills)
  const dailyTotalExpenses = Comission.getTotalEarnings (daySchedules, {value: 'cutilei'}, null)

  return (
    <Container>
      <AdminNavbar/>
      <InfoContainer>
        <SubTitle>Total do dia {day}</SubTitle>
        <Info>
          <Row>
            <InfoText>Faturamento diário bruto:</InfoText>
            <MoneyTextMask value={dailyTotalEarnings}/>
          </Row>
          <Row>
            <InfoText>Despesa diária com salões:</InfoText>
            <MoneyTextMask value={dailyTotalExpenses}/>
          </Row>
          <Row>
          <SubTitle>Receita deduzida de despesas:</SubTitle>
            <MoneyTextMask value={(dailyTotalEarnings - dailyTotalExpenses)}/>
          </Row>
        </Info>
      </InfoContainer>
      {dayBills.map ((bill, index) => (
        <Bill data={bill} key={index}/>
      ))}
    </Container>
  )
}

export default withRouter (Daily)
