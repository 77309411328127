import React, { useState, useEffect } from 'react'
import AuthService from '../../../services/auth'
import cutileiApi from '../../../services/cutileiApi'
import TimeWindow from '../../../services/timeWindow'
import Select from '../../../components/Inputs/Select'
import CheckBox from '../../../components/CheckBox'
import AlertDialog from '../../../components/AlertDialog'
import { Formik } from 'formik'
import professionalWorkingDaysValidator from '../../../validators/professionalWorkingDaysValidator'
import { ReactComponent as Loading } from '../../../icons/loading2.svg'
import { ReactComponent as ButtonLoading } from '../../../icons/loading.svg'
import { DateTime } from 'luxon'

import {
  Container,
  Form,
  Button,
  SubTitle,
  FormField,
  Label,
  WarningText,
  ErrorContainer,
  Row
} from '../styles'

// accessLevels.find (
//   level => level.id === values.access_level?.value
// )?.codename === 'service'

function EditProfessionalAgenda ({
  professional,
  professionalWorkingDays,
  professionalCategories,
  onWorkingDaysUpdated: handleWorkingDaysUpdated
}) {
  require ('cleave.js/dist/addons/cleave-phone.br')

  const [loading, setLoading] = useState (true)
  const [businessWorkingDays, setBusinessWorkingDays] = useState ([])
  const [workStartTimeWindows, setWorkStartTimeWindows] = useState ({})
  const [workEndTimeWindows, setWorkEndTimeWindows] = useState ({})
  const [cutileiStartTimeWindows, setCutileiStartTimeWindows] = useState ({})
  const [cutileiEndTimeWindows, setCutileiEndTimeWindows] = useState ({})
  const [errorMessage, setErrorMessage] = useState (null)
  const [showOkDialog, setShowOkDialog] = useState (false)
  const businessId = AuthService.getBusinessId ()
  const token = AuthService.getToken ()

  useEffect (() => {
    getData ()
  }, [])

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  const getData = async () => {
    try {
      const { data: businessWorkingDays } = await cutileiApi.get (`/businesses/${businessId}/working_days`)
      const timeWindows = {...businessWorkingDays.map (day => TimeWindow.getTimeWindows (day.opening, day.closing))}
      const cutileiTimeWindows = {...businessWorkingDays.map (day => day.accepts_cutilei
        ? TimeWindow.getTimeWindows (day.cutilei_opening, day.cutilei_closing)
        : TimeWindow.getTimeWindows (day.opening, day.closing)
      )}

      setBusinessWorkingDays (businessWorkingDays)
      setWorkStartTimeWindows (timeWindows)
      setWorkEndTimeWindows (timeWindows)
      setCutileiStartTimeWindows (cutileiTimeWindows)
      setCutileiEndTimeWindows (cutileiTimeWindows)
    } catch (error) {
      console.log (error)
    } finally {
      setLoading (false)
    }
  }

  const handleTimeSelected = (name, option, workingDays, setFieldValue) => {
    const dayIndex = parseInt (name.split ('.')[1])
    const businessClosing = DateTime.fromISO (businessWorkingDays[dayIndex].closing).toFormat ('H:mm')
    const businessOpening = DateTime.fromISO (businessWorkingDays[dayIndex].opening).toFormat ('H:mm')

    if (name.includes ('work_start')) {
      setWorkEndTimeWindows ({
        ...workEndTimeWindows,
        [dayIndex]: TimeWindow.getTimeWindows (option.value, businessClosing)
      })
      setCutileiStartTimeWindows ({
        ...cutileiStartTimeWindows,
        [dayIndex]: TimeWindow.getTimeWindows (
          option.value, workingDays[dayIndex].work_end.value || businessClosing
        )
      })
    } else if (name.includes ('work_end')) {
      setWorkStartTimeWindows ({
        ...workStartTimeWindows,
        [dayIndex]: TimeWindow.getTimeWindows (businessOpening, option.value)
      })
      setCutileiEndTimeWindows ({
        ...cutileiEndTimeWindows,
        [dayIndex]: TimeWindow.getTimeWindows (
          workingDays[dayIndex].work_start.value || businessOpening, option.value
        )
      })
    }

    setFieldValue ('working_days', workingDays.map ((day, index) => ({
      ...day,
      work_start: name.includes ('work_start') && !day.work_start
        ? option : index === dayIndex && name.includes ('work_start')
          ? option : day.work_start,
      work_end: name.includes ('work_end') && !day.work_end
        ? option : index === dayIndex && name.includes ('work_end')
          ? option : day.work_end
    })))
  }

  const handleCutileiTimeSelected = (name, option, workingDays, setFieldValue) => {
    const dayIndex = parseInt (name.split ('.')[1])
    const lastTimeWindowIndex = cutileiEndTimeWindows[dayIndex].length -1

    if (name.includes ('cutilei_opening')) {
      setCutileiEndTimeWindows ({
        ...cutileiEndTimeWindows,
        [dayIndex]: TimeWindow.getTimeWindows (
          option.value, cutileiEndTimeWindows[dayIndex][lastTimeWindowIndex].value
        )
      })
    } else if (name.includes ('cutilei_closing')) {
      setCutileiStartTimeWindows ({
        ...cutileiStartTimeWindows,
        [dayIndex]: TimeWindow.getTimeWindows (
          cutileiStartTimeWindows[dayIndex][0].value, option.value
        )
      })
    }

    setFieldValue ('working_days', workingDays.map ((day, index) => ({
      ...day,
      cutilei_start: name.includes ('cutilei_start') && day.cutilei_start === ''
        ? option : index === dayIndex && name.includes ('cutilei_start')
          ? option : day.cutilei_start,
      cutilei_end: name.includes ('cutilei_end') && day.cutilei_end === ''
        ? option : index === dayIndex && name.includes ('cutilei_end')
          ? option : day.cutilei_end
    })))
  }

  const toggleOkDialog = () => setShowOkDialog (!showOkDialog)

  return (
    <Container>
      {loading ? <Loading style={{marginBottom: 20}}/> : (
        <Formik
          validationSchema={professionalWorkingDaysValidator}
          initialValues={{
            'working_days': businessWorkingDays.map (day => {
              const workingDay = professionalWorkingDays.find (workingDay => workingDay.id === day.id)

              if (workingDay) {
                const work_start = DateTime.fromISO (workingDay.work_start).toFormat ('H:mm')
                const work_end = DateTime.fromISO (workingDay.work_end).toFormat ('H:mm')
                const cutilei_start = DateTime.fromISO (workingDay.cutilei_start || workingDay.work_start).toFormat ('H:mm')
                const cutilei_end = DateTime.fromISO (workingDay.cutilei_end || workingDay.work_end).toFormat ('H:mm')

                return { 
                  ...workingDay,
                  work_start: { label: work_start, value: work_start },
                  work_end: { label: work_end, value: work_end },
                  cutilei_start: { label: cutilei_start, value: cutilei_start },
                  cutilei_end: { label: cutilei_end, value: cutilei_end },
                  selected: true
                } 
              } else return {
                ...day, work_start: '', work_end: '', accepts_cutilei: false,
                cutilei_start: '', cutilei_end: '', selected: false
              }
            })
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setErrorMessage (null)
            setSubmitting (true)

            try {
              const working_days = values.working_days.filter (day => day.selected).map (day => ({
                ...day,
                work_start: day.work_start.value,
                work_end: day.work_end.value,
                cutilei_start: day.cutilei_start.value,
                cutilei_end: day.cutilei_end.value
              }))

              await cutileiApi.post (`/professionals/${professional.id}/working_days`,
                {working_days},
                requestConfig
              )

              setSubmitting (false)
              toggleOkDialog ()
              handleWorkingDaysUpdated (working_days)
            } catch (error) {
              if (error.response?.status === 400) setErrorMessage (error.response.data.message)
              setSubmitting (false)
              console.log (error.response.data)
            }
          }}
        >
          {function WorkingDaysForm ({
            values,
            errors,
            touched,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
            setTouched,
            handleSubmit
          }) {
            const handleDaySelected = (value, index) => {
              setFieldValue (`working_days.${index}`, {
                ...values.working_days[index],
                ...value === true && {
                    work_start: '',
                    work_end: '',
                    cutilei_start: '',
                    cutilei_end: '',
                    accepts_cutilei: false
                  },
                  selected: value
                })
    
              if (value === true) setTouched ({
                [`working_days.${index}.work_start`]: false,
                [`working_days.${index}.work_end`]: false,
                [`working_days.${index}.cutilei_start`]: false,
                [`working_days.${index}.cutilei_end`]: false,
                [`working_days.${index}.accepts_cutilei`]: false
              })
            }
    
            const handleAcceptsCutileiSelected = (value, index) => {
              setFieldValue (`working_days.${index}`, {
                ...values.working_days[index],
                ...value === true ? {
                  cutilei_start: values.working_days[index].work_start,
                  cutilei_end: values.working_days[index].work_end
                } : {
                  cutilei_opening: '',
                  cutilei_end: ''
                },
                accepts_cutilei: value
              })
    
              if (value === true) setTouched ({
                [`working_days.${index}.cutilei_start`]: false,
                [`working_days.${index}.cutilei_end`]: false
              })
            }
    

            return (
              <Form onSubmit={handleSubmit}>
                <SubTitle>Disponibilidade de atendimento da agenda do profissional</SubTitle>
                <Row style={{alignItems: 'flex-start'}}>
                  {values.working_days.map ((day, index) => (
                    <FormField key={day.id}>
                      <Row style={{marginBottom: -5, alignItems: 'center'}}>
                        <CheckBox
                          id={`working_days.${index}.selected`}
                          style={{marginRight: 6}}
                          value={values.working_days[index].selected}
                          onValueChange={value => handleDaySelected (value, index)}
                        />
                        <Label htmlFor={`working_days.${index}.selected`}>
                          {day.name}
                        </Label>
                      </Row>
                      {values.working_days[index].selected && (
                        <>
                          <Select
                            name={`working_days.${index}.work_start`}
                            placeholder='Início...'
                            value={values.working_days[index].work_start}
                            options={workStartTimeWindows[index]}
                            onChange={(name, option) => handleTimeSelected (name, option, values.working_days, setFieldValue)}
                            onBlur={setFieldTouched}
                            containerStyles={{marginTop: '10px'}}
                            error={errors.working_days?.[index]}
                            touched={touched.working_days?.[index]}
                          />
                          {touched.working_days?.[index]?.work_start && errors.working_days?.[index]?.work_start && (
                            <WarningText>{errors.working_days[index].work_start}</WarningText>
                          )}
                          <Select
                            name={`working_days.${index}.work_end`}
                            placeholder='Término...'
                            value={values.working_days[index].work_end}
                            options={workEndTimeWindows[index]}
                            onChange={(name, option) => handleTimeSelected (name, option, values.working_days, setFieldValue)}
                            onBlur={setFieldTouched}
                            containerStyles={{marginTop: '10px'}}
                            error={errors.working_days?.[index]}
                            touched={touched.working_days?.[index]}
                          />
                          {touched.working_days?.[index]?.work_end && errors.working_days?.[index]?.work_end && (
                            <WarningText>{errors.working_days[index].work_end}</WarningText>
                          )}
                          {professionalCategories.some (c => c.name.toLowerCase () === 'manicure e pedicure') && (
                            <>
                              <Row style={{marginTop: 6, marginBottom: -5}}>
                                <CheckBox
                                  id={`working_days.${index}.accepts_cutilei`}
                                  style={{marginTop: 3, marginRight: 6}}
                                  value={values.working_days[index].accepts_cutilei}
                                  onValueChange={value => handleAcceptsCutileiSelected (value, index)}
                                />
                                <Label htmlFor={`working_days.${index}.accepts_cutilei`}>
                                  Atende Cutilei
                                </Label>
                              </Row>
                              {values.working_days[index].accepts_cutilei && (
                                <>
                                  <Select
                                    name={`working_days.${index}.cutilei_start`}
                                    placeholder='Abertura...'
                                    value={values.working_days[index].cutilei_start}
                                    options={cutileiStartTimeWindows[index]}
                                    onChange={(name, option) => handleCutileiTimeSelected (name, option, values.working_days, setFieldValue)}
                                    onBlur={setFieldTouched}
                                    containerStyles={{marginTop: '10px'}}
                                    error={errors.working_days?.[index]}
                                    touched={touched.working_days?.[index]}
                                  />
                                  {touched.working_days?.[index]?.cutilei_start && errors.working_days?.[index]?.cutilei_start && (
                                    <WarningText>{errors.working_days[index].cutilei_start}</WarningText>
                                  )}
                                  <Select
                                    name={`working_days.${index}.cutilei_end`}
                                    placeholder='Fechamento...'
                                    value={values.working_days[index].cutilei_end}
                                    options={cutileiEndTimeWindows[index]}
                                    onChange={(name, option) => handleCutileiTimeSelected (name, option, values.working_days, setFieldValue)}
                                    onBlur={setFieldTouched}
                                    containerStyles={{marginTop: '10px'}}
                                    error={errors.working_days?.[index]}
                                    touched={touched.working_days?.[index]}
                                  />
                                  {touched.working_days?.[index]?.cutilei_end && errors.working_days?.[index]?.cutilei_end && (
                                    <WarningText>{errors.working_days[index].cutilei_end}</WarningText>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </FormField>
                  ))}
                </Row>
                {errorMessage && (
                  <ErrorContainer>
                    <p>{errorMessage}</p>
                  </ErrorContainer>
                )}
                <AlertDialog
                  visible={showOkDialog}
                  title='Sucesso'
                  message='Alterações salvas!'
                  confirmText='Ok'
                  onConfirm={toggleOkDialog}
                  onClose={toggleOkDialog}
                />
                <Button type='submit' disabled={isSubmitting}>
                  {isSubmitting ? <ButtonLoading/> : 'Salvar alterações'}
                </Button>
              </Form>
            )
          }}
        </Formik>
      )}
    </Container>
  )
}

export default EditProfessionalAgenda
