import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import AuthService from '../../services/auth'
import Navbar from '../../components/Navbar'
import EditAgenda from './EditAgenda'
import EditServices from './EditServices'
import EditBundles from './EditBundles'
import EditProducts from './EditProducts'
import ConfigProfessionals from './ConfigProfessionals'
import ConfigBankAccounts from './ConfigBankAccounts'
import EditBusiness from './EditBusiness'
import ChangePassword from './ChangePassword'

import {
  Container,
  TabRow,
  TabButton,
  Title
} from './styles'

function Config ({ history }) {
  const configOption = history.location.state?.configOption ?? null
  const isBusiness = AuthService.isBusiness ()
  const [option, setOption] = useState (
    configOption ?? localStorage.getItem ('config_tab') ?? (
      isBusiness ? 'personal' : 'change_password'
    )
  )

  const OPTION_LIST = [
    ...isBusiness ? [
      {label: 'Meus dados', value: 'personal', component: <EditBusiness/>},
      {label: 'Agenda', value: 'agenda', component: <EditAgenda/>},
      {label: 'Serviços', value: 'services', component: <EditServices/>},
      {label: 'Pacotes', value: 'bundles', component: <EditBundles/>},
      {label: 'Produtos', value: 'products', component: <EditProducts/>},
      {label: 'Profissionais', value: 'professionals', component: <ConfigProfessionals/>},
      {label: 'Contas bancárias', value: 'bank_accounts', component: <ConfigBankAccounts/>},
      {label: 'Alterar senha', value: 'change_password', component: <ChangePassword/>}
    ] : [
      {label: 'Alterar senha', value: 'change_password', component: <ChangePassword/>}
    ]
  ]

  return (
    <Container>
      <Navbar/>
      <Title>
        Configurações
      </Title>
      <TabRow>
        {OPTION_LIST.map ((o, index) =>
          <TabButton
            key={index}
            selected={option === o.value}
            color={option === o.value ? '#FFC100' : '#FFFFFF'}
            onClick={() => {
              setOption (o.value)
              localStorage.setItem ('config_tab', o.value)
            }}
          >
            {o.label}
          </TabButton>
        )}
      </TabRow>
      {OPTION_LIST.find (o => o.value === option).component}
    </Container>
  )
}

export default withRouter (Config)
