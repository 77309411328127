import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import cutileiApi from '../../services/cutileiApi'
import AuthService from '../../services/auth'
import Navbar from '../../components/Navbar'
import EditProfessionalPersonalInfo from './PersonalInfo'
import EditProfessionalAgenda from './Agenda'
import EditProfessionalServices from './Services'
import ChangeProfessionalPassword from './ChangePassword'
import { ReactComponent as Loading } from '../../icons/loading2.svg'

import {
  Container,
  TabButton,
  Row,
  Title
} from './styles'

function EditProfessional ({ match }) {
  require ('cleave.js/dist/addons/cleave-phone.br')

  const [loading, setLoading] = useState (true)
  const [option, setOption] = useState ('personal')
  const [professional, setProfessional] = useState (null)
  const [professionalCategories, setProfessionalCategories] = useState ([])
  const [professionalWorkingDays, setProfessionalWorkingDays] = useState ([])
  const { id: professional_id } = match.params
  const token = AuthService.getToken ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  const OPTION_LIST = [
    {
      label: 'Dados pessoais',
      value: 'personal',
      component: <EditProfessionalPersonalInfo professional={professional}/>
    },
    {
      label: 'Agenda',
      value: 'agenda',
      component: <EditProfessionalAgenda
        professional={professional}
        professionalWorkingDays={professionalWorkingDays}
        professionalCategories={professionalCategories}
        onWorkingDaysUpdated={setProfessionalWorkingDays}
      />
    },
    {
      label: 'Serviços',
      value: 'services',
      component: <EditProfessionalServices
        professional={professional}
        professionalCategories={professionalCategories}
        professionalWorkingDays={professionalWorkingDays}
        onCategoriesUpdated={setProfessionalCategories}
        onWorkingDaysUpdated={setProfessionalWorkingDays}
      />
    },
    {
      label: 'Alterar senha',
      value: 'change_password',
      component: <ChangeProfessionalPassword professional={professional}/>
    }
  ]

  useEffect (() => {
    getData ()
  }, [])

  const getData = async () => {
    try {
      const { data: professional } = await cutileiApi.get (
        `/professionals/${professional_id}`, requestConfig
      )
      const { data: professionalCategories } = await cutileiApi.get (
        `/professionals/${professional.id}/service_categories?status=active`, requestConfig
      )
      const { data: professionalWorkingDays } = await cutileiApi.get (
        `/professionals/${professional?.id}/working_days`
      )

      setProfessional (professional)
      setProfessionalCategories (professionalCategories)
      setProfessionalWorkingDays (professionalWorkingDays)
    } catch (error) {
      console.log (error)
    } finally {
      setLoading (false)
    }
  }

  return (
    <Container>
      <Navbar/>
      <Title>
        Editar profissional{professional && ` - ${professional.name}`}
      </Title>
      {loading ? <Loading/> : (
        <>
          <Row style={{gap: 10}}>
            {OPTION_LIST.map ((o, index) =>
              <TabButton
                key={index}
                selected={option === o.value}
                color={option === o.value ? '#FFC100' : '#FFFFFF'}
                onClick={() => setOption (o.value)}
              >
                {o.label}
              </TabButton>
            )}
          </Row>
          {OPTION_LIST.find (o => o.value === option).component}
        </>
      )}
    </Container>
  )
}

export default withRouter (EditProfessional)
