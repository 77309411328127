import React, { useState } from 'react'
import cutileiApi from '../../../../services/cutileiApi'
import AuthService from '../../../../services/auth'
import OptionDialog from '../../../OptionDialog'
import Logo from '../../../../icons/logo-cutilei-simple.png'
import NumberInput from '../../../Inputs/NumberInput'
import MoneyInput from '../../../Inputs/MoneyInput'
import Select from '../../../Inputs/Select'
import { ReactComponent as ButtonLoading } from '../../../../icons/loading2.svg'
import ReactTooltip from 'react-tooltip'
import { Formik } from 'formik'
import * as FA from 'react-icons/fa'
import { TbFreeRights } from 'react-icons/tb'
import { DateTime } from 'luxon'

import {
  Container,
  FormField,
  Row,
  Image,
  Label,
  InfoText,
  MoneyTextMask,
  Button
} from './styles'

function BillSchedule ({
  data: schedule,
  services,
  professionals,
  isFree = false,
  showFreeItemsValue = false,
  enableEditting = true,
  onToggleEdit: handleToggleEdit,
  onEdit: handleEdit,
  onDelete: handleDelete,
  onRemoveFromBill: handleRemoveFromBill
}) {
  const [editting, setEditting] = useState (false)
  const [showDeleteDialog, setShowDeleteDialog] = useState (false)
  const [errorMessage, setErrorMessage] = useState (null)
  const [deleting, setDeleting] = useState (false)
  const { id: schedule_id } = schedule
  const firstName = schedule.professional.name.split(' ')[0]
  const token = AuthService.getToken ()
  const businessId = AuthService.getBusinessId ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  const deleteSchedule = async () => {
    setDeleting (true)
    try {
      await cutileiApi.delete (`/schedules/${schedule.id}`, requestConfig)
      setDeleting (false)
      handleDelete (schedule)
    } catch (error) {
      setDeleting (false)
      console.log (error)
    }
  }

  const removeScheduleFromBill = async () => {
    setDeleting (true)
    try {
      await cutileiApi.put (
        `/schedules/${schedule.id}/activation`, {billActive: false}, requestConfig
      )
      setDeleting (false)
      handleRemoveFromBill (schedule)
    } catch (error) {
      setDeleting (false)
      console.log (error)
    }
  }

  const handleServiceSelected = async (name, option, setFieldValue) => {
    const service = option.value

    setFieldValue ('price', service.price)
    setFieldValue ('comission_percentage', service.comission_percentage * 100)
    setFieldValue (name, option)
  }

  const toggleEditting = () => {
    setEditting (!editting)
    handleToggleEdit (!editting)
  }
  const toggleDeleteDialog = () => setShowDeleteDialog (!showDeleteDialog)

  return editting ? (
    <Formik
      initialValues={{
        'professional': {
          label: schedule.professional.nickname || firstName,
          value: schedule.professional.id
        },
        'service': {label: schedule.service.name, value: schedule.service},
        'date': schedule.date,
        'time': schedule.time,
        'price': schedule.price,
        'comission_percentage': schedule.comission_percentage * 100
      }}
      onSubmit={async (values, { setSubmitting }) => {
        const scheduleValues = {
          ...values,
          businessId: businessId,
          serviceId: values.service.value.id,
          professionalId: values.professional.value,
          comission_percentage: values.comission_percentage / 100
        }

        try {
          const { data: schedule } = await cutileiApi.put (
            `/schedules/${schedule_id}`, scheduleValues, requestConfig
          )

          setSubmitting (false)
          setEditting (false)
          handleEdit (schedule)
        } catch (error) {
          console.log (error)
          if (error.response.data) setErrorMessage (error.response.data.message)
          setSubmitting (false)
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        handleBlur, 
        handleSubmit
      }) => (
        <Container editting={editting} enableEditting={enableEditting}>
          <FormField>
            <Select
              name='service'
              placeholder='Serviço...'
              noOptionsMessage='Este profissional ainda não possui serviços cadastrados'
              value={values.service}
              options={services.map (service => ({
                label: service.name,
                value: service,
              }))}
              onChange={(name, option) => handleServiceSelected (name, option, setFieldValue)}
              onBlur={setFieldTouched}
              error={errors.service}
              touched={touched.service}
            />
          </FormField>
          <FormField>
            <Select
              name='professional'
              placeholder='Profissional...'
              value={values.professional}
              options={professionals.map (professional => ({
                label: professional.nickname || professional.name.split (' ')[0],
                value: professional.id
              }))}
              onChange={setFieldValue}
              onBlur={setFieldTouched}
              error={errors.professional}
              touched={touched.professional}
              containerStyles={{width: '100%'}}
            />
          </FormField>
          <InfoText>
            {DateTime.fromISO (schedule.date).toFormat ('dd/MM/yyyy')}
          </InfoText>
          <InfoText>
            {schedule.time}
          </InfoText>
          <FormField>
            <NumberInput
              id='comission_percentage'
              name='comission_percentage'
              value={values.comission_percentage}
              minValue={0}
              maxValue={100}
              onChange={setFieldValue}
              style={{width: '100%', marginLeft: -10}}
            />
          </FormField>
          <Row>
            <FormField>
              <MoneyInput
                name='price'
                placeholder='Preço'
                value={values.price}
                onValueChange={setFieldValue}
                onBlur={handleBlur ('price')}
                style={{width: '100%'}}
              />
            </FormField>
          </Row>
          <Row>
            <Button type='button' onClick={handleSubmit} disabled={isSubmitting}>
              {isSubmitting ? (
                <ButtonLoading style={{margin: 0}}/>
              ) : (
                <FA.FaSave color='#35A7FF' size={16}/>
              )}
            </Button>
            <Button type='button' onClick={toggleEditting}>
              <FA.FaTimes color='#FF3939' size={18}/>
            </Button>
          </Row>
          {/* {errorMessage && (
            <ErrorContainer>
              <InfoText>{errorMessage}</InfoText>
            </ErrorContainer>
          )} */}
        </Container>
      )}
    </Formik>
  ) : (
    <Container
      cutileiSchedule={schedule.cutilei_schedule}
      editting={editting}
      enableEditting={enableEditting}
    >
      <Row>
        {schedule.cutilei_schedule && <Image src={Logo} alt='Agendamento Cutilei'/>}
        <InfoText>
          {schedule.service.name}
        </InfoText>
      </Row>
      <InfoText>
        {schedule.professional.nickname || firstName}
      </InfoText>
      <InfoText>
        {DateTime.fromISO (schedule.date).toFormat ('dd/MM/yyyy')}
      </InfoText>
      <InfoText>
        {schedule.time}
      </InfoText>
      <InfoText>
        {schedule.comission_percentage * 100}%
      </InfoText>
      <Row data-for={isFree ? schedule.id : null} data-tip>
        <MoneyTextMask
          value={(
            isFree ? showFreeItemsValue ? schedule.discount_price : 0 : schedule.price
          ) ?? 0}
          style={{justifySelf: 'center'}}
        />
        {isFree && <TbFreeRights size={24} color='#FFC100'/>}
        <ReactTooltip
          id={schedule.id}
          effect='float'
          place='top'
          backgroundColor='#252525'
          tooltipRadius='10'
        >
          <Label style={{color: '#FFFFFF'}}>
            O cliente possui este serviço em pacotes adquiridos, portante ele não será cobrado
          </Label>
        </ReactTooltip>
      </Row>
      {!schedule.cutilei_schedule && enableEditting && (
        <Row>
          <Button onClick={toggleEditting}>
            <FA.FaEdit color='#35A7FF' size={18}/>
          </Button>
          <Button onClick={toggleDeleteDialog}>
            {deleting ? (
              <ButtonLoading style={{margin: 0}}/>
            ) : (
              <FA.FaTrash color='#FF3939' size={16}/>
            )}
          </Button>
        </Row>
      )}
      <OptionDialog
        visible={showDeleteDialog}
        title='Atenção!'
        message={
          <InfoText>Deseja cancelar este agendamento ou apenas remover o agendamento da comanda?</InfoText>
        }
        options={[
          {label: 'Cancelar agendamento', onClick: deleteSchedule, dangerous: true},
          {label: 'Remover da comanda', onClick: removeScheduleFromBill, dangerous: false}
        ]}
        onClose={toggleDeleteDialog}
        containerStyle={{width: 430}}
        buttonStyle={{width: 180}}
      />
    </Container>
  )
}

export default BillSchedule
