import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Container = styled(Link)`
  background-color: #FFFFFF;
  width: 100%;
  height: 40px;
  margin-bottom: 10px;
  box-shadow: 0px 2px 10px -6px #252525;
  text-decoration: none;
  border-radius: 15px;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 10px;
  justify-content: space-between; 
  align-items: center;
  padding: 7px 12px;

  &:hover {
    background-color: #F7F7F7;
  }
`

export const InfoText = styled.p`
  font-size: 14px;
  text-align: center;
  color: #000000;
  margin: 0px;
`

export const SubTitle = styled(InfoText)`
  font-weight: bold;
`
